import React from 'react'
import { Link } from 'gatsby'
import LinkImage from 'gatsby-image'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { FaLinkedinIn, FaFacebookF, FaInstagram } from 'react-icons/fa'

import { mobileContainerStyle, laptopContainerStyle } from '../Common'
import { H4, H3, P, SmallText } from '../../styleElements'
import Line from '../Line'
import BackToTop from '../BackToTop'
import mq from '../../utils/mediaQuery'
import useFooter from '../../queries/useFooter'

const Footer = () => {
  const theme = useTheme()
  const nodes = useFooter()

  return (
    <Root>
      <Container>
        <Content>
          <Body>
            <Header>
              <Link to="/">
                <LinkImage
                  fixed={nodes.piccollageWhiteLogo}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </Link>
            </Header>
            <LinksWrapper>
              <LinkColumn>
                <Link to="/press">
                  <SmallLink>Press</SmallLink>
                </Link>
                <Link to="/events">
                  <SmallLink>Events</SmallLink>
                </Link>
                <Link to="/user-feedback">
                  <SmallLink>User Feedback</SmallLink>
                </Link>
                <a
                  href="https://artwork.piccollage.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmallLink>Artists</SmallLink>
                </a>
                <Link to="/contact?modal=business">
                  <SmallLink>Business</SmallLink>
                </Link>
              </LinkColumn>
              <LinkColumn>
                <Link to="/products">
                  <BigLink>Products</BigLink>
                </Link>
                <a
                  href="https://piccollage.zendesk.com/hc/en-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmallLink>Product Support</SmallLink>
                </a>
                <Link to="/tos">
                  <SmallLink>Terms of Service</SmallLink>
                </Link>
                <Link to="/privacy">
                  <SmallLink>Privacy Policy</SmallLink>
                </Link>
              </LinkColumn>
              <LinkColumn>
                <Link to="/careers">
                  <BigLink>Careers</BigLink>
                </Link>
                <a
                  href="https://jobs.lever.co/piccollage"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmallLink>Jobs</SmallLink>
                </a>
                <a
                  href="https://piccollage.notion.site/Online-Info-Session-240c0b3166ff449784023cda16be1f9a?pvs=4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmallLink>Online Info Session</SmallLink>
                </a>
              </LinkColumn>
              <LinkColumn>
                <Link to="/contact">
                  <BigLink>Contact</BigLink>
                </Link>
                <Link to="/address">
                  <SmallLink>Office</SmallLink>
                </Link>
              </LinkColumn>
            </LinksWrapper>
          </Body>
          <SocialWrapper>
            <SocialTitle>Follow Us</SocialTitle>
            <Social>
              {socialLinks.map(item => (
                <SocialIcon key={item.to} href={item.to} target="_blank">
                  {item.icon}
                </SocialIcon>
              ))}
            </Social>
          </SocialWrapper>
        </Content>

        <FooterLine color={theme.color.white}></FooterLine>
        <CopyRight>
          <CopyRightItem>PicCollage Company</CopyRightItem>
          <CopyRightItem>
            Copyright {new Date().getFullYear()}. All rights reserved.
          </CopyRightItem>
        </CopyRight>
      </Container>
      <FooterBackToTop />
    </Root>
  )
}

const socialLinks = [
  {
    icon: <FaInstagram />,
    to: 'https://www.instagram.com/piccollage.company'
  },
  {
    icon: <FaFacebookF />,
    to: 'https://www.facebook.com/piccollage.zh'
  },
  {
    icon: <FaLinkedinIn />,
    to: 'https://www.linkedin.com/company/piccollage/'
  }
]

const Root = styled.footer`
  padding: 40px 20px 20px;
  background-color: ${props => props.theme.color.black};
  position: relative;

  ${mq.md} {
    padding-bottom: 40px;
  }
`

const Container = styled.article`
  ${mobileContainerStyle}
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mq.md} {
    ${laptopContainerStyle}
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.md} {
    flex-direction: row;
  }
`

const Header = styled.header`
  margin-bottom: 30px;
`

const Body = styled.nav`
  margin-bottom: 20px;

  ${mq.md} {
    margin-bottom: 0;
    flex-grow: 1;
  }
`

const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const LinkColumn = styled.ul`
  display: flex;
  flex-direction: column;
  min-width: 110px;
  margin-right: 30px;
  margin-bottom: 24px;

  ${mq.md} {
    margin-right: 45px;
    margin-bottom: 0px;
  }
`

const footerLinkStyle = props => css`
  color: ${props.theme.color.white};
  border-bottom: 1px solid transparent;
  margin-bottom: 10px;
  transition: all 0.3s ease-in;

  &:hover {
    color: ${props.theme.color.primary1};
    border-bottom: 1px solid ${props.theme.color.primary1};
  }
`

const BigLink = styled(H4)`
  ${footerLinkStyle}
`

const SmallLink = styled(P)`
  ${footerLinkStyle}
`

const SocialWrapper = styled.div``

const SocialTitle = styled(P)`
  color: ${props => props.theme.color.gray2};
  margin-bottom: 10px;
`

const Social = styled.ul`
  display: flex;
`

const SocialIcon = styled.a`
  margin-right: 10px;
  color: white;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.color.primary1};
  }

  &:last-of-type {
    margin-right: 0;
  }
`

const FooterLine = styled(Line)`
  margin: 30px 0 15px;
  width: 100%;
`

const CopyRight = styled.footer`
  display: flex;
  flex-direction: column;
`

const CopyRightItem = styled(SmallText)`
  color: ${props => props.theme.color.white};
`

const FooterBackToTop = styled(BackToTop)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`

export default Footer
